exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-infinite-js": () => import("./../../../src/templates/BlogPostInfinite.js" /* webpackChunkName: "component---src-templates-blog-post-infinite-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-next-js": () => import("./../../../src/templates/BlogPostNext.js" /* webpackChunkName: "component---src-templates-blog-post-next-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-custom-post-types-custom-post-type-overview-js": () => import("./../../../src/templates/custom-post-types/CustomPostTypeOverview.js" /* webpackChunkName: "component---src-templates-custom-post-types-custom-post-type-overview-js" */),
  "component---src-templates-custom-post-types-custom-post-type-single-js": () => import("./../../../src/templates/custom-post-types/CustomPostTypeSingle.js" /* webpackChunkName: "component---src-templates-custom-post-types-custom-post-type-single-js" */),
  "component---src-templates-index-lang-js": () => import("./../../../src/templates/IndexLang.js" /* webpackChunkName: "component---src-templates-index-lang-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

